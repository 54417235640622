<template>
  <standard-layout>
    <template #heading><h1>So erreichst Du uns</h1></template>
    <template #main>
      <contact-form/>
    </template>
  </standard-layout>
</template>

<script>
import StandardLayout from "@/layouts/StandardLayout";
import ContactForm from "@/components/ContactForm";

export default {
  components: {
    StandardLayout, ContactForm
  }
}
</script>
