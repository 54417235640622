<template>
  <div>
    Du möchtest uns Deine Fragen stellen?
    Einfach das Formular vewenden
    oder gleich anrufen unter
    <a :href="'tel:' + configuration.phone">
      <font-awesome-icon icon="mobile-alt"/>&nbsp;{{ configuration.phone }}</a>.
    <div class="row mt-2">
      <div class="col-12">
        <label for="emailInput">Deine Email-Adresse</label>
        <input id="emailInput" type="email" autofocus required class="form-control" v-model="from"/>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-12">
        <label for="messageInput">Deine Nachricht an uns</label>
        <textarea rows="8" id="messageInput" class="form-control" v-model="message"></textarea>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-12">
        <vue-recaptcha
            ref="recaptcha"
            @verify="captchaVerified"
            @expired="captchaVerified = false"
            theme="clean"
            :sitekey="recaptchaKey"
            :loadRecaptchaScript="true">
        </vue-recaptcha>
      </div>
    </div>
    <div class="input-group mt-2">
      <button class="btn btn-primary"
              :disabled="!submittable"
              @click.prevent="submit">
        Nachricht senden
      </button>
    </div>
  </div>
</template>

<script>
import VueRecaptcha from 'vue-recaptcha'
import {mapGetters} from "vuex";

export default {
  data() {
    return {
      recaptchaKey: process.env.VUE_APP_SPABOOT_RECAPTCHA_KEY,
      message: null,
      from: null,
      captchaToken: null
    }
  },
  computed: {
    ...mapGetters(['configuration']),
    submittable(){
      return this.message && this.from && this.captchaToken
    }
  },
  methods: {
    submit() {
      console.log(this.captchaToken)
      this.$api.post('/messages', {
        message: this.message,
        from: this.from,
        captchaToken: this.captchaToken
      }).then(() => {
        this.showMessage({
          message: 'Deine Nachricht wurde gesendet. Wir melden uns schnellstmöglich zurück',
          type: 'info'
        })
      }).catch(error => {
        this.handleError(error)
      }).finally(() => {
        this.reset();
      })
    },
    captchaVerified(token) {
      this.captchaToken = token
    },
    reset() {
      this.captchaToken = null
      this.message = ''
      this.from = ''
      this.$refs.recaptcha.reset()
    }
  },
  components: {
    VueRecaptcha
  }
}
</script>
